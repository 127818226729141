import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { rem } from "src/utils/styling"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./Testimonials.scss"
import { AiOutlineRight } from "react-icons/ai"
import Testimonial1 from "src/images/task_page/testimonial1.svg"
import Testimonial2 from "src/images/task_page/testimonial2.svg"
import Testimonial3 from "src/images/task_page/testimonial3.jpg"
import PropTypes from "prop-types"

const Container = styled.div`
  margin: ${rem(60)}rem 0;
  @media screen and (min-width: 1100px) {
    margin: ${rem(80)}rem ${rem(80)}rem;
  }
  @media screen and (max-width: 600px) {
    margin: ${rem(60)}rem 0;
  }
`

const TestimonyContent = styled.div`
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 600px) {
    display: block !important;
  }
`

const TestimonyFooter = styled(TestimonyContent)`
  justify-content: space-between;
  margin-left: ${rem(20)}rem;
  margin-top: ${rem(10)}rem;
`

const Content = styled.div`
  &:focus {
    outline: none;
  }
  em {
    color: #00b8c5;
    font-style: normal;
  }
`

function Testimonials(props) {
  const { name } = props

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    appendDots: dots => (
      <div>
        {name === "compare" ? (
          <ul className="compare-dots">{dots}</ul>
        ) : (
          <ul className="dots">{dots}</ul>
        )}
      </div>
    ),
  }

  return (
    <Container>
      <Slider {...settings}>
        <Content>
          <TestimonyContent>
            <img
              src={Testimonial1}
              alt={"Adam Winter"}
              className="testimonial-image"
            />
            <Content>
              <p className="testimonial-text">
                “It is enormously freeing to be able to put an upfront cost with
                a feature and have it done quickly. With Archimydes we were able
                to complete a production deployment of our first Lambda based
                microservice in <em>under 4 days, and for about a $1,000.</em>”
              </p>
              <TestimonyFooter>
                <p
                  className={
                    name === "compare"
                      ? "testimonial-footer-text-two"
                      : "testimonial-footer-text"
                  }
                >
                  <strong>Adam Winter </strong>- CTO, Clarus R+D
                </p>
                <Link
                  to={"/case-study/clarus"}
                  className={
                    name === "compare"
                      ? "testimonial-footer-link-two"
                      : "testimonial-footer-link"
                  }
                >
                  Read Case Study
                  <AiOutlineRight className="testimonial-footer-icon" />
                </Link>
              </TestimonyFooter>
            </Content>
          </TestimonyContent>
        </Content>
        <Content>
          <TestimonyContent>
            <img
              src={Testimonial2}
              alt={"Adam Winter"}
              className="testimonial-image"
            />
            <Content>
              <p className="testimonial-text">
                “Archimydes very quickly has become an integral part of our
                toolkit for success. Working within the constraints of an
                FDA-regulated product they were able to build an{" "}
                <em>
                  entirely custom anti-malware/anti-virus solution in less than
                  two weeks!
                </em>
                ”
              </p>
              <TestimonyFooter>
                <p
                  className={
                    name === "compare"
                      ? "testimonial-footer-text-two"
                      : "testimonial-footer-text"
                  }
                >
                  <strong>Juan Jiminez </strong>- CEO, Accurkardia
                </p>
                <Link
                  to={"/case-study/accurkardia"}
                  className={
                    name === "compare"
                      ? "testimonial-footer-link-two"
                      : "testimonial-footer-link"
                  }
                >
                  Read Case Study
                  <AiOutlineRight className="testimonial-footer-icon" />
                </Link>
              </TestimonyFooter>
            </Content>
          </TestimonyContent>
        </Content>
        <Content>
          <TestimonyContent>
            <img
              src={Testimonial3}
              alt={"Asher Jay"}
              className="testimonial-image-1"
            />
            <Content>
              <p className="testimonial-text">
                “We handed them Invision mocks, which they converted into and a
                series of user stories, and handed us back functioning pages for
                User Acceptance Testing on a near-daily basis. We went from{" "}
                <em>high-fidelity mockups to a functioning MVP in 6 weeks.</em>”
              </p>
              <TestimonyFooter>
                <p
                  className={
                    name === "compare"
                      ? "testimonial-footer-text-two"
                      : "testimonial-footer-text"
                  }
                >
                  <strong>Asher Jay </strong>- National Geographic Explorer,
                  MYOB
                </p>
                <Link
                  to={"/case-study/myob"}
                  className={
                    name === "compare"
                      ? "testimonial-footer-link-two"
                      : "testimonial-footer-link"
                  }
                >
                  Read Case Study
                  <AiOutlineRight className="testimonial-footer-icon" />
                </Link>
              </TestimonyFooter>
            </Content>
          </TestimonyContent>
        </Content>
      </Slider>
    </Container>
  )
}

export default Testimonials

Testimonials.propTypes = {
  name: PropTypes.string,
}
